.cuartosTarjeta{
    width: 82.5%;
    display: flex;
    gap: 0.65rem;
    align-self: center;
    overflow: hidden;
    border-radius: 10px;
    background: linear-gradient(0deg, rgba(220, 220, 220, 0.85) 5%, rgba(255,255,255,0.85) 85%);
    box-shadow: 2px 2px 10px rgba(0,0,0,.1);
    
    .cuartosTarjetaFotos{
        width: 67.5%;
        height: clamp(400px, 60vh, 800px);
        object-fit: cover;
        position: relative;

        .carouselCaption{
            display: none;
        }
        
        div {
            div{
                height: 100%;
                object-fit: cover;
            }
        }
        
        .cuartosMasInfoButton{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: none;
            cursor: pointer;
        }
    }
    
    .cuartosTarjetaInfo{
        width: 32.5%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        
        .cuartosTarjetaInfoDetalle{
            display: flex;
            flex-direction: column;
            height: 67.50%;
            align-items: center;
            justify-content: space-between;
            width: 82.5%;
            
            .cuartosTarjetaInfoLI{
                display: flex;
                flex-direction: column;
                align-self: start;
                font-size: 1.4rem;

                .masInfoClose{
                    display: none;
                }
                  
                .masInfoClose1 {
                    display: none;
                }
                  
                .masInfoClose2{
                    transform: skew(-10deg);
                    text-align: center;
                }
            }
            
            .cuartosTarjetaButtons{
                display: flex;
                flex-direction: column;
                gap: 0.65rem;
                margin-bottom: 1rem;
                width: 80%;
            }
        }
    }
}
  
  @media (max-width: 992px) {

    .cuartosTarjeta{
      flex-direction: column;
      gap: 0.5rem;
      
        .cuartosTarjetaFotos{
          width: 100%;
          height: clamp(350px, 50vh, 650px);
        }
        
        .cuartosTarjetaInfo{
            width: 100%;
            
            .cuartosTarjetaInfoDetalle{
                flex-direction: column;
                align-items: center;
                justify-content: space-around;
                width: 82.5%;
                
                .cuartosTarjetaInfoLI{
                    font-size: 1.3rem;
                    width: 100%;
                    display: flex;
                    align-items: center;
                }
                
                .cuartosTarjetaButtons{
                    flex-direction: row;
                    justify-content: space-around;
                    width: 100%;

                    button{
                        width: 40%;
                    }
                }
            }
        }
    }
    .cuartosTarjeta2 > div:nth-child(1){
      order: 2;
    }
  
    .cuartosTarjeta2 >div:nth-child(2){
      order: 1;
    }
  }
  
  @media (max-width: 576px) {

    .cuartosTarjeta{
      flex-direction: column;
      gap: 0.35rem;
    
        .cuartosTarjetaFotos{
        width: 100%;
        height: clamp(300px, 40vh, 500px);
            
            .cuartosMasInfoButton{
                display: flex;
                z-index: 99;
            }
        }
        
        .cuartosTarjetaInfo{
            width: 100%;
            padding: 0.5rem;
            
            .cuartosTarjetaInfoDetalle{
                flex-direction: column;
                align-items: center;
                justify-content: space-around;
                width: 82.5%;
                position: relative;
                
                
                .cuartosTarjetaInfoLI{
                    display: none;
                    position: fixed;
                    background-color: rgba(0, 0, 0, 0.9); /* Transparent background */
                    justify-content: center;
                    align-items: center;
                    color: white;
                    font-size: 2rem;
                    padding: 0rem;
                    border-radius: 20px;
                    width: 90%;
                    height: 70%;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    z-index: 999; /* Make sure it's on top */
                    pointer-events: all; /* Prevent interaction when hidden */
                    transition: opacity 0.3s ease;
                    
                    .masInfoClose {
                        display:flex;
                        position: absolute;
                        top: 10px;
                        right: 20px;
                        font-size: 24px;
                        cursor: pointer;
                        z-index: 1000;
                    }
                    
                    .masInfoClose1 {
                        display:flex;
                        font-size: 24px;
                    }
                    
                    .masInfoClose2{
                        display: none;
                    }
                    
                }
                .cuartosTarjetaButtons{
                    flex-direction: row;
                    width: 100%;
                }
            }
        }
    }
}

  @media (min-width: 576px) {
    .cuartosTarjeta{
        z-index: 1;
    }
  }