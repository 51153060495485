
.navLink{
    opacity: 1;
    
    &:hover{
        opacity: .7;
        color: rgb(132, 30, 30);
        line-height: 25px;
        text-decoration: underline;
    }   
}

.navBarSocial{
    display: none;
}
    
@media (max-width: 576px) {
    .navBarSocial{
        display: flex;
        margin-top: .65rem;
        justify-content: center;
        font-size: 1.4rem;
        gap: 18px;
    }
}