.Banner{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
  
.tituloBanner{
    text-align: center;
    font-size: 3.2rem;
    font-weight: 600;
    letter-spacing: 5px;
    padding: 0.5rem;
    margin-top: 1rem;
    margin-left: auto;
    margin-right: auto;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    width: 80%;
}
  
.subTituloBanner{
    letter-spacing: 1px;
    font-size: 2.6rem;
    text-align: center;
}

h2{
    font-size: 2.2rem;
}

h3{
    font-size: 2rem;
}

h4{
    font-size: 1.8rem;
}

h5{
    font-size: 1.6rem;
}

h6{
    font-size: 1.4rem;
}

p{
    font-size: 1.25rem;
}

@media (max-width: 992px) {
      
    .tituloBanner{
        font-size: 4.2rem;
        letter-spacing: 1px;
    }
  
      .subTituloBanner{
          font-size: 2.2rem;
    }
    
    h2{
        font-size: 2rem;
    }
    
    h3{
        font-size: 1.8rem;
    }
    
    h4{
        font-size: 1.6rem;
    }
    
    h5{
        font-size: 1.4rem;
    }
    
    h6{
        font-size: 1.2rem;
    }

    p{
        font-size: 1.15rem;
    }
}

@media (max-width: 576px) {
    .Banner{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
      
    .tituloBanner{
        font-size: 2.2rem;
        letter-spacing: 1px;
    }
  
      .subTituloBanner{
          letter-spacing: 1px;
          font-size: 1.8rem;
          text-align: center;
    }
    
    h2{
        font-size: 1.6rem;
    }
    
    h3{
        font-size: 1.4rem;
    }
    
    h4{
        font-size: 1.2rem;
    }
    
    h5{
        font-size: 1.1rem;
    }
    
    h6{
        font-size: 1.1rem;
    }

    p{
        font-size: 1.1rem;
    }
}