.App {
    background-color: #1b1c1e;
    min-height: 5vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(5px + 1vmin);
    color: white;
}

.contactWhap{
    position: fixed;
    bottom: 12px;
    right: 12px;
    z-index: 100000;
}
  
.contactWhapInner{
    display: flex;
    align-items: center;
    justify-content: center;
    border-Radius:50%; 
    background: #25d366;
    padding: 8px;
    width: 68px;
    height: 68px;
}