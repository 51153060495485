.surfContainer{
    display: flex;
    flex-direction: column;

    .surfTripTitulo{

        h2{
            text-align: left;
            font-weight: 600;
        }
        h5{
            transform: skew(-15deg);
            padding: 2rem;
        }
    }

    .surfAmmenities{
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;

        div {
            height: 250px;
            padding: 1rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            gap: 10px;

            h5 {
                margin-top: auto
            }
            
            div{
                width: 160px;
                height: 160px;

                img{
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }
    }
}

.surfContainer2{
    display: flex;
    flex-direction: column;
    div {
        background-color: rgba(255,255,255,0.4);
        background-blend-mode: lighten;
        background-size: cover;
        background-position: center;

        div{
            padding: 4rem 4rem 4rem 1rem;
            background-color: #ffffff00;
        
            h5 {
                font-size: 1.8rem;
                font-weight: 500;
            } 

            h6 {
                font-size: 1.05rem;
                font-weight: 500;
                opacity: 0;
            }
        }
        &:hover{        
            background-color: #ffffff;

            h6{
                transition: 0.5s;
                opacity: 1;
            }
        }
    }
}

.surfContainer3{
    margin-left: auto;
    margin-right: auto;
    margin-top: 0.5rem;
    padding-bottom: 1rem;
    padding-top: 1rem;
}

.ConoceOlas{
    height: auto;
    width: 65%;
    object-fit: contain;
    border-radius: 30px;
    overflow: hidden;
    
    .SurfTripOlas{
        display: flex;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        flex-direction: column;
        justify-content: end;
        align-items: center;

        h3{
            font-size: 6rem;
            color: white;
            margin-bottom: auto;
            margin-top: 5rem;
            text-shadow: 2px 2px 20px black;
        }

        p{
            font-size: 2rem;
            margin: 0px;
            padding: 2rem;
            color: white;
            letter-spacing: -1px;
            font-style: italic;
            text-align: center;
            background-color: #00000060;
        }
    }
}

  
@media (max-width: 992px) {

    .surfContainer{

        .surfTripTitulo{
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;

            h2{
                text-align: center;
            }
            h5{
                text-align: center;                
            }
        }
    }

    .ConoceOlas{
      width: 80%;
      border-radius: 20px;
      
      .SurfTripOlas {

            h3{
              font-size: 4rem;
              margin-top: 3rem;
            }
            
            p{
                font-size: 1.6rem;
                padding: 1rem;
            }
        } 
    }
}
  
@media (max-width: 576px) {
    .ConoceOlas{
      width: 95%;
      border-radius: 10px;
      
      .SurfTripOlas {

            h3{
              font-size: 3rem;
              margin-top: 1rem;
            }
            
            p{
                font-size: 1rem;
                padding: 0.65rem;
            }
        }
    }
}
  
  
  