  
.hostelCardsGrid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 3rem 3rem 6rem 3rem 3rem 6rem 3rem 3rem 6rem 3rem 3rem 6rem 3rem 3rem 6rem;
    gap: 3rem;
    z-index: 99;
    
    .hostelBanner{
        display: flex;
        flex-direction: column;
        border-radius: 8px;
        max-width: 400px;
        width: 100%;
        justify-self: center;
        overflow: hidden;
        background: linear-gradient(0deg, rgb(255, 255, 255) 0%, rgb(128, 255, 187) 100%);
        
        .hostelBannerFoto {
            height: 80%;
            width: 100%;
            
            img{
                object-fit: cover;
                height: 100%;
                width: 100%;
            }
        }
        
        .hostelBannerInfo{
            display: flex;
            align-items: center;
            margin-top: auto;
            margin-bottom: auto;
            padding-inline: 2rem;
            
            h2{
                font-size: 2.4rem;
                margin: 0rem;
            }
            button{
                width: 30%;
                margin-left: auto;
            }
        }
    }

    .hostelInfo{
        p {
            font-size: 1.4rem;
            margin: 0rem;
            
            u{
                text-shadow: 1px 1px 20px rgba(0, 0, 0, 0.1);
                font-size: 1.6rem;
            }
        }
    }
 
    .hostelBanner1{
        grid-row: 1/6;
        grid-column: 1;
    }
        
    .hostelBanner2{
        grid-row: 4/9;
        grid-column: 2;
    }
        
    .hostelBanner3{
        grid-row: 7/12;
        grid-column: 1;
    }
        
    .hostelBanner4{
        grid-row: 10/15;
        grid-column: 2;
    }
        
    .hostelInfo1{
        grid-row: 2;
        grid-column: 2;
        width: 55%;
        transform: rotate(359deg) skew(353deg, 353deg)
    }
        
    .hostelInfo2{
        grid-row: 6;
        grid-column: 1;
        width: 100%;
        transform: rotate(1deg) skew(4deg, 360deg);
        font-size: 1rem;
        letter-spacing: -1px;
        text-transform: uppercase;
        text-align: center;
    }
        
    .hostelInfo3{
        grid-row: 9;
        grid-column: 2;
        width: 77.5%;
        margin-left: auto;
        transform: rotate(4deg) skew(8deg, 3deg)
    }
        
    .hostelInfo4{
        grid-row: 12;
        grid-column: 1;
        width: 50%;
        margin-left: auto;
        margin-right: auto;
        transform: rotate(1deg) skew(359deg, 357deg);
    }
}
  

  
@media (max-width: 992px) {
    .hostelCardsGrid{
        grid-template-rows: 1.5rem 1.5rem 4rem 1.5rem 1.5rem 4rem 1.5rem 1.5rem 4rem 1.5rem 1.5rem 4rem 1.5rem 1.5rem 4rem;

        .hostelBanner{

            .hostelBannerInfo{

                padding-inline: 1.25rem;
                
                h2{
                    font-size: 2rem;
                }
            }
        }

        .hostelInfo1{
          transform: rotate(359deg) skew(353deg, 353deg) translate(0px, -75px);
          width: 65%
        }
        .hostelInfo3{
            transform: rotate(4deg) skew(8deg, 3deg) translate(0px, -32.5px)
        }
    }
}
  
@media (max-width: 768px) {

    .hostelCardsGrid{
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 1rem;

        .hostelInfo1{
          transform: rotate(359deg) skew(353deg, 353deg) translate(0px, -55px)
        }
    }

}
  
@media (max-width: 576px){

    .hostelCardsGrid{
        .hostelBanner{

            .hostelBannerInfo{
                padding-inline: 1.2rem;
                
                button{
                    width: 40%;
                }
                
                h2{
                    font-size: 1.8rem;
                }
            }
        }
        
        .hostelInfo1{
            transform: rotate(359deg) skew(353deg, 353deg) translate(0px, -55px);
            width: 80%;
        }
        .hostelInfo2{
            padding-bottom: 2rem;
        }
        .hostelInfo3{
            transform: rotate(4deg) skew(8deg, 3deg) translate(0px, -32.5px);
            padding-top: 1rem;
        }
        .hostelInfo4{
            transform: rotate(1deg) skew(359deg, 357deg) translate(-20px, -82.5px);
            padding-top: 1rem;
        }
    }
}
    