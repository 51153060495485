/* GALLERY BREAKFAST */
.gallery {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 60px; /* Space for fixed navbar */
}
  
.photo-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: center;
}
  
.photo-item img {
    width: 230px;
    height: 230px;
    object-fit: cover;
    border-radius: 5px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: transform 0.3s ease;
    
    &:hover {
    transform: scale(1.025);
    }
}
  
  /* Modal Styles */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}
  
.modal-content {
    position: relative;
    background-color: transparent;
    max-width: 70vw; /* Adjust this based on desired modal width */
    max-height: 70vh; /* Adjust this based on desired modal height */
    overflow: hidden;
    text-align: center;
    display: flex;
    justify-content: center; /* Horizontally center image */
    align-items: center; /* Vertically center image */
}
  
  /* Image Container */
.image-container {
    width: 100%; /* Make the container take up the full width of the modal */
    height: 100%; /* Ensure the container maintains a full-height scale */
    display: flex;
    justify-content: center;
    align-items: center;

    img {
    object-fit: contain; /* Ensure the image scales proportionally */
    width: 100%; /* Scale the image to the full width of its container */
    height: auto; /* Maintain the image's aspect ratio */
    }
}
.description {
    margin-top: 10px;
    font-size: 16px;
    color: #fff; /* Changed description color to white for better contrast */
}
  
  /* Arrow Styles */
.nav-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 60px; /* Increased the size of the arrowhead significantly */
    color: white;
    background: none; /* Remove background */
    border: none;
    cursor: pointer;
    z-index: 1;
    transition: opacity 0.3s ease;
    
    &:hover {
    opacity: 0.8; /* Add hover effect */
    }
    
}

.nav-btn.left {
    left: 10px; /* Position left arrow */
}
  
.nav-btn.right {
    right: 10px; /* Position right arrow */
}
  
  /* Close Button Styles */
.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none; /* Removed circle background */
    color: white;
    border: none;
    font-size: 24px; /* Made the "X" smaller and similar in size to the arrows */
    cursor: pointer;
    z-index: 2;
    transition: opacity 0.3s ease;
    
    &:hover {
        opacity: 0.8; /* Slight opacity change on hover for better interactivity */
    }
}
  
.show-more-btn {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 5px;
    
    &:hover {
        background-color: #0056b3;
    }
}
  
@media(max-width: 992px) {
    .photo-item img {
      width: 200px;
      height: 200px;
    }
}
  
@media(max-width: 576px) {
    .photo-item img {
      width: 160px;
      height: 160px;
    }
    .gallery {
      padding-inline: 15px; /* Space for fixed navbar */
    }
    .modal-content {
      max-width: 92.5vw; /* Reduced modal size by 10% */
      max-height: 92.5vh; /* Reduced modal size by 10% */
    }
}