  
.aboutContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 1.2rem;
    gap: 1.25rem;
    transform: translateY(-18%);
    border-radius: 25px;
    border: 2px;
    margin: 0rem 6rem;
    background: linear-gradient(282deg, rgba(235,235,193,1) 21%, rgba(243,239,191,1) 47%, rgba(185,232,222,1) 93%);
  
    div {
        width: 50%;
        padding: 1rem;
    }

    .aboutusphoto{
        display: flex;
        width: 50%;
        height: 100%;
        max-height: 450px;
    }
}
  
.aboutContainer2{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 1rem;
  
    .aboutUs{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

        div{
        width: 50%;
        display: flex;
        flex-direction: column;
        gap: .65rem;
        justify-content: center;
        align-items: center;
        padding-left: 1rem;
        padding-right: 1rem;
        margin: .5rem;

            h2{
            font-weight: 600;
            }

            img{
            width: 350px; 
            height:350px;
            border-radius: 5px;
            box-shadow: 0px 2px 10px rgba(200, 200, 200, 0.8);
            }

            p {
            max-width: 300px;
            text-align: center;
            }
        }
    }
}

.aboutContainer3{
    background: linear-gradient(282deg, rgba(223,219,171,.5) 33%, rgba(216,208,114,.5) 51%, rgba(223,219,171,.5) 67%, rgba(87,208,155,.5) 80%, rgba(34,210,190,.5) 93%);
    padding: 3rem;

    .locacionRosa {
        display: flex;
        flex-direction: column;
        
        p{
            padding-left: 2rem;
        }
    }
}

.aboutContainer4{
    display: flex;
    flex-direction: column;
    padding: 2rem;
    background-color: rgba(255, 255, 255, 0.70);

    .aboutReviews{
        display: flex;
        flex-direction: column;
        padding: 1rem;
        gap: .5rem;
        margin-left: auto;
        margin-right: auto;

        .reviewcontainer {
            display: flex;
            flex-direction: column;
            padding: 1.2rem;
            min-height: auto;
            border-width: 2px;
            border-color: rgba(152, 152, 152, 0.3);
            border-radius: 20px;
            box-shadow: 0px 2px 20px rgba(165, 165, 165, 0.2);
        }
    }
}

@media (max-width: 992px) {
    .aboutContainer{
        margin: 0rem 4rem;
        padding: .65rem;
        gap: .9rem;

        div {
        width: 100%;
        padding: .5rem;
        }

        .aboutusphoto{
        width: 100%;
        }

        .tituloBanner{
        margin-top: 0rem;
        }
    }

    .aboutContainer2 {
        div {
            img{
                width: 275px; 
                height:275px;
            }
        }
    }

    .aboutContainer3{

        .locacionRosa {
            p{
            padding-left: 1.4rem;
            }
        }

        .mapFlo{
            width: 475px;
            height: 375px;
        }
    }
}
  
@media (max-width: 768px) {
    .aboutContainer2 {
        flex-direction: column;

        .aboutUs{
            
            div {
                width: 100%;
                img{
                    width: 275px; 
                    height:275px;
                }
            }
        }
    }
}

@media (max-width: 576px) {
    .aboutContainer {
        padding: .4rem;
        margin: 0rem 2rem;
        height: auto;
        gap: 0.5rem;

        div {
        width: 100%;
        padding: .4rem;
        }

        .aboutusphoto{
        height: 325px;
        }
    }

    .aboutContainer2 {
        div {
            width: 100%;
            padding-inline: .65rem;

            img{
                width: 250px; 
                height:250px;
            }
        }
    }

    .aboutContainer3{

        padding-inline: .9rem;

        .locacionRosa {
            h4{
            text-align: center;
            }
            
            p{
            padding-left: .65rem;
            text-align: center;
            }
        }

        .mapFlo{
            width: 275px;
            height: 275px;
        }
    }
    
    .aboutContainer4{
        padding: .75rem;
        height: 500px;
        overflow-y: scroll;

        h3{
            text-align: center;
            padding-inline: .5rem;
        }

        .reviewcontainer{
            width: 100%;
        }
    }
}
  
@media (max-width: 576px) {
    .aboutus3 {
      padding: .75rem;
      height: 500px;
      overflow-y: scroll;
    }

    .aboutus3 > h3 {
      text-align: center;
      padding-inline: .5rem;
    }
  
    .reviewcontainer{
      width: 100%;
    }
  }
  
