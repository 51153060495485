
.title-home{
    position: absolute;
    top: 50%;
    left: 25%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h2{
        font-size: 10rem;
        color: #000000;
    }

    h3{
        font-size: 2rem;
        color: #000000;
        transform: skew(-15deg);
        margin-bottom: 2rem;
    }

    div{
        display: flex;
        gap: 1rem;
        width: 75%;
        justify-content: center;
    }

    .button-home{
        font-size: 1.4rem;
        border-radius: 30px;
    }
  }
 
  
  @media (max-width: 576px) {
    .title-home{
      top: 50%;
      left: 50%;
      width: 100%;

      h2{
        font-size: 7rem;
      }

      h3{
        font-size: 1.6rem;
        margin-bottom: 2.6rem;
    }

      div{
        flex-direction: column;
        width: 30%;
      }
      
      .button-home{
        top: 70%;
        left: 50%;
        font-size: 1.1rem;

      }
    }
  }